import background from './background.mjs'
import {
  imgproxySettings,
  mobileImgproxySettings,
  resolvePaceOptionsFromSKU as resolveOptionsFromSKU,
  stripMatteOrInfinitePrefix
} from './shared.mjs'

export const frame = {
  id: 'frame',
  name: 'Frame',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    Fclear: {
      pathTemplate: 'gs://sg360/220520/Ultras/uf/uf_Fclear_{nnn}.png'
    },
    grey: {
      pathTemplate: 'gs://sg360/220520/Ultras/uf/uf_Idarkgrey_{nnn}.png'
    },
    navy: {
      pathTemplate: 'gs://sg360/220520/Ultras/uf/uf_Inavy_{nnn}.png'
    },
    black: {
      pathTemplate: 'gs://sg360/220520/Ultras/uf/uf_Iblack_{nnn}.png'
    },
    mint: {
      pathTemplate: 'gs://sg360/220520/Ultras/uf/uf_Mmint_{nnn}.png'
    },
    pink: {
      pathTemplate: 'gs://sg360/220520/Ultras/uf/uf_Mpink_{nnn}.png'
    },
    stone: {
      pathTemplate: 'gs://sg360/220520/Ultras/uf/uf_Mstone_{nnn}.png'
    },
    white: {
      pathTemplate: 'gs://sg360/220520/Ultras/uf/uf_Mwhite_{nnn}.png'
    },
    slateblue: {
      pathTemplate: 'gs://sg360/240409/slateblue/uf_Rslateblue_{nnn}.png'
    }
  }
}

export const nose = {
  id: 'nose',
  name: 'Nose',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    none: {
      disabled: true
    },
    Fclear: {
      pathTemplate: 'gs://sg360/220520/Ultras/un/un_Fclear_{nnn}.png'
    },
    grey: {
      pathTemplate: 'gs://sg360/220520/Ultras/un/un_Idarkgrey_{nnn}.png'
    },
    navy: {
      pathTemplate: 'gs://sg360/220520/Ultras/un/un_Mnavy_{nnn}.png'
    },
    black: {
      pathTemplate: 'gs://sg360/220520/Ultras/un/un_Mblack_{nnn}.png'
    },
    mint: {
      pathTemplate: 'gs://sg360/220520/Ultras/un/un_Mmint_{nnn}.png'
    },
    pink: {
      pathTemplate: 'gs://sg360/220520/Ultras/un/un_Mpink_{nnn}.png'
    },
    stone: {
      pathTemplate: 'gs://sg360/220520/Ultras/un/un_Mstone_{nnn}.png'
    },
    white: {
      pathTemplate: 'gs://sg360/220520/Ultras/un/un_Mwhite_{nnn}.png'
    },
    slateblue: {
      pathTemplate: 'gs://sg360/240409/slateblue/un_Rslateblue_{nnn}.png'
    }
  }
}

export const earsocks = {
  id: 'earsocks',
  name: 'Earsocks',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    Rblue: {
      pathTemplate: 'gs://sg360/220520/Ultras/ues/ues_Rblue_{nnn}.png'
    },
    Rgold: {
      pathTemplate: 'gs://sg360/220520/Ultras/ues/ues_Rgold_{nnn}.png'
    },
    Rgrey: {
      pathTemplate: 'gs://sg360/220520/Ultras/ues/ues_Rgrey_{nnn}.png'
    },
    Rnavy: {
      pathTemplate: 'gs://sg360/220520/Ultras/ues/ues_Rnavy_{nnn}.png'
    },
    Rstone: {
      pathTemplate: 'gs://sg360/220520/Ultras/ues/ues_Rstone_{nnn}.png'
    },
    Rblack: {
      pathTemplate: 'gs://sg360/220520/Ultras/ues/ues_Rblack_{nnn}.png'
    },
    Rmint: {
      pathTemplate: 'gs://sg360/220520/Ultras/ues/ues_Rmint_{nnn}.png'
    },
    Rwhite: {
      pathTemplate: 'gs://sg360/220520/Ultras/ues/ues_Rwhite_{nnn}.png'
    },
    Rslateblue: {
      pathTemplate: 'gs://sg360/240409/slateblue/ues_Rslateblue_{nnn}.png'
    }
  }
}

export const icons = {
  id: 'icons',
  name: 'Icons',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    Bblack: {
      pathTemplate: 'gs://sg360/220520/Ultras/ui/ui_Bblack_{nnn}.png'
    },
    Bblue: {
      pathTemplate: 'gs://sg360/220520/Ultras/ui/ui_Bblue_{nnn}.png'
    },
    Bgold: {
      pathTemplate: 'gs://sg360/220520/Ultras/ui/ui_Bgold_{nnn}.png'
    },
    Bgunmetal: {
      pathTemplate: 'gs://sg360/220520/Ultras/ui/ui_Bgunmetal_{nnn}.png'
    },
    Bpurple: {
      pathTemplate: 'gs://sg360/220520/Ultras/ui/ui_Bpurple_{nnn}.png'
    },
    Bred: {
      pathTemplate: 'gs://sg360/220520/Ultras/ui/ui_Bred_{nnn}.png'
    },
    Brosegold: {
      pathTemplate: 'gs://sg360/220520/Ultras/ui/ui_Brosegold_{nnn}.png'
    },
    Bsilver: {
      pathTemplate: 'gs://sg360/220520/Ultras/ui/ui_Bsilver_{nnn}.png'
    },
    Bwhite: {
      pathTemplate: 'gs://sg360/220520/Ultras/ui/ui_Bwhite_{nnn}.png'
    }
  }
}

export const lenses = {
  id: 'lens',
  name: 'Lenses',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    clear: {
      pathTemplate: 'gs://sg360/220520/Ultras/ule/ule_clear_{nnn}.png'
    },
    fire: {
      pathTemplate: 'gs://sg360/220520/Ultras/ule/ule_fire_{nnn}.png'
    },
    gold: {
      pathTemplate: 'gs://sg360/220520/Ultras/ule/ule_gold_{nnn}.png'
    },
    green: {
      pathTemplate: 'gs://sg360/220520/Ultras/ule/ule_green_{nnn}.png'
    },
    hvblue: {
      pathTemplate: 'gs://sg360/220520/Ultras/ule/ule_hvblue_{nnn}.png'
    },
    IRhvblue: {
      pathTemplate: 'gs://sg360/220520/Ultras/ule/ule_IRhvblue_{nnn}.png'
    },
    IRsmoke: {
      pathTemplate: 'gs://sg360/220520/Ultras/ule/ule_IRsmoke_{nnn}.png'
    },
    purple: {
      pathTemplate: 'gs://sg360/220520/Ultras/ule/ule_purple_{nnn}.png'
    },
    silverblue: {
      pathTemplate: 'gs://sg360/220520/Ultras/ule/ule_silverblue_{nnn}.png'
    },
    smoke: {
      pathTemplate: 'gs://sg360/220520/Ultras/ule/ule_smoke_{nnn}.png'
    },
    chrome4: {
      pathTemplate: 'gs://sg360/240815/ule_chrome4_{nnn}.png'
    }
  }
}

export const reflection = {
  id: 'reflections',
  name: 'Reflections',
  isReflection: true,
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    none: {
      disabled: true
    },
    r5pc: {
      pathTemplate: 'gs://sg360/220520/Ultras/ule Cloud Overlay/5/ule_Cloud Overlay 5_{nnn}.png'
    },
    r10pc: {
      pathTemplate: 'gs://sg360/220520/Ultras/ule Cloud Overlay/10/ule_Cloud Overlay 10_{nnn}.png'
    }
  }
}

export const iris = {
  id: 'iris',
  name: 'Iris Mask',
  imgproxySettings,
  mobileImgproxySettings,
  isIrisMask: true,
  options: {
    none: {
      disabled: true
    },
    mask: {
      pathTemplate: 'gs://sg360/220520/Ultras/ule/ule_Iris Mask_{nnn}.png'
    }
  }
}

export const engraving = {
  id: 'engraving',
  name: 'Engraving',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    eN: {
      pathTemplate: 'gs://sg360/220520/Ultras/ulg/ulg_N_{nnn}.png'
    }
  }
}

function resolveOptions (customisedProduct) {
  const design = customisedProduct.state.choices
  const getSKUWithoutPrefix = sku => sku?.split('_')?.[1]

  return {
    frame: stripMatteOrInfinitePrefix(getSKUWithoutPrefix(design.uf)),
    bottom: stripMatteOrInfinitePrefix(getSKUWithoutPrefix(design.sb) || 'none'),
    nose: stripMatteOrInfinitePrefix(getSKUWithoutPrefix(design.sn) || 'none'),
    icons: getSKUWithoutPrefix(design.i),
    engraving: 'eN',
    iris: 'mask',
    earsocks: getSKUWithoutPrefix(design.es),
    lens: getSKUWithoutPrefix(customisedProduct.lensChoice.skuWithoutLensTech),
    reflections: 'r5pc'
  }
}

export default {
  supports360: false,
  layers: [
    background,
    frame,
    nose,
    earsocks,
    icons,
    lenses,
    iris,
    engraving,
    reflection
  ],
  resolveOptions,
  resolveOptionsFromSKU
}