import background from './background.mjs'
import {
  imgproxySettings,
  mobileImgproxySettings,
  resolveLifestyleOptionsFromSKU as resolveOptionsFromSKU,
  stripMatteOrInfinitePrefix
} from './shared.mjs'

export const frame = {
  id: 'frame',
  name: 'Frame',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    clear: {
      pathTemplate: 'gs://sg360/230615/sg11f_Fclear_v02/sg11f_Fclear_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230615/sg11f_Fclear_v02/sg11f_Fclear_{nnn}.png'
    },
    black: {
      pathTemplate: 'gs://sg360/050623/Tokas/tof/tof_Iblack_{nnn}.png',
      staticPathTemplate: 'gs://sg360/050623/Tokas/tof/tof_Iblack_{nnn}.png'
    },
    blacktortoise: {
      pathTemplate: 'gs://sg360/050623/Tokas/tof/tof_Iblacktortoise_{nnn}.png',
      staticPathTemplate: 'gs://sg360/050623/Tokas/tof/tof_Iblacktortoise_{nnn}.png'
    },
    grey: {
      pathTemplate: 'gs://sg360/050623/Tokas/tof/tof_Igrey_{nnn}.png',
      staticPathTemplate: 'gs://sg360/050623/Tokas/tof/tof_Igrey_{nnn}.png'
    },
    navy: {
      pathTemplate: 'gs://sg360/050623/Tokas/tof/tof_Inavy_{nnn}.png',
      staticPathTemplate: 'gs://sg360/050623/Tokas/tof/tof_Inavy_{nnn}.png'
    },
    tortoise: {
      pathTemplate: 'gs://sg360/050623/Tokas/tof/tof_Itortoise_{nnn}.png',
      staticPathTemplate: 'gs://sg360/050623/Tokas/tof/tof_Itortoise_{nnn}.png'
    }
  }
}

export const logos = {
  id: 'logos',
  name: 'Logos',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    Bblack: {
      pathTemplate: 'gs://sg360/050623/Tokas/toll/toll_Bblack_{nnn}.png',
      staticPathTemplate: 'gs://sg360/050623/Tokas/toll/toll_Bblack_{nnn}.png'
    },
    Bblue: {
      pathTemplate: 'gs://sg360/050623/Tokas/toll/toll_Bblue_{nnn}.png',
      staticPathTemplate: 'gs://sg360/050623/Tokas/toll/toll_Bblue_{nnn}.png'
    },
    Bgold: {
      pathTemplate: 'gs://sg360/050623/Tokas/toll/toll_Bgold_{nnn}.png',
      staticPathTemplate: 'gs://sg360/050623/Tokas/toll/toll_Bgold_{nnn}.png'
    },
    Bgunmetal: {
      pathTemplate: 'gs://sg360/050623/Tokas/toll/toll_Bgunmetal_{nnn}.png',
      staticPathTemplate: 'gs://sg360/050623/Tokas/toll/toll_Bgunmetal_{nnn}.png'
    },
    Bsilver: {
      pathTemplate: 'gs://sg360/050623/Tokas/toll/toll_Bsilver_{nnn}.png',
      staticPathTemplate: 'gs://sg360/050623/Tokas/toll/toll_Bsilver_{nnn}.png'
    },
    Bwhite: {
      pathTemplate: 'gs://sg360/230615/sg11i_Bwhite_v02/sg11i_Bwhite_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230615/sg11i_Bwhite_v02/sg11i_Bwhite_{nnn}.png'
    }
  }
}

export const lenses = {
  id: 'lens',
  name: 'Lenses',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    blue: {
      pathTemplate: 'gs://sg360/050623/Tokas/tole/tole_blue_{nnn}.png',
      staticPathTemplate: 'gs://sg360/050623/Tokas/tole/tole_blue_{nnn}.png'
    },
    chrome: {
      pathTemplate: 'gs://sg360/230615/sg11le_chrome/sg11le_chrome_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230615/sg11le_chrome/sg11le_chrome_{nnn}.png'
    },
    fire: {
      pathTemplate: 'gs://sg360/050623/Tokas/tole/tole_fire_{nnn}.png',
      staticPathTemplate: 'gs://sg360/050623/Tokas/tole/tole_fire_{nnn}.png'
    },
    gold: {
      pathTemplate: 'gs://sg360/230615/sg11le/sg11le_gold_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230615/sg11le/sg11le_gold_{nnn}.png'
    },
    silverblue: {
      pathTemplate: 'gs://sg360/230615/sg11le/sg11le_silverblue_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230615/sg11le/sg11le_silverblue_{nnn}.png'
    },
    smoke: {
      pathTemplate: 'gs://sg360/050623/Tokas/tole/tole_smoke_{nnn}.png',
      staticPathTemplate: 'gs://sg360/050623/Tokas/tole/tole_smoke_{nnn}.png'
    },
    clear: {
      pathTemplate: 'gs://sg360/270624/Tokas/tole/tole_clear_{nnn}.png',
      staticPathTemplate: 'gs://sg360/270624/Tokas/tole/tole_clear_{nnn}.png'
    }
  }
}

export const engraving = {
  id: 'engraving',
  name: 'Engraving',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    eN: {
      pathTemplate: 'gs://sg360/050623/Tokas/tolg/tolg_N_{nnn}.png'
    },
    eP: {
      pathTemplate: 'gs://sg360/050623/Tokas/tolg/tolg_P_{nnn}.png'
    },
    e8: {
      pathTemplate: 'gs://sg360/050623/Tokas/tolg/tolg_8P_{nnn}.png'
    },
    e8P: {
      pathTemplate: 'gs://sg360/050623/Tokas/tolg/tolg_8_{nnn}.png'
    },
    none: {
      disabled: true
    }
  }
}

export function resolveOptions (customisedProduct) {
  const engraving = {
    '4ko': 'eN',
    '4kop': 'eP',
    '8ko': 'e8',
    '8kop': 'e8P'
  }[customisedProduct.lensTech] || 'none'

  return {
    frame: stripMatteOrInfinitePrefix(customisedProduct.frameChoice?.skuWithoutPrefix),
    lens: customisedProduct.lensChoice.skuWithoutLensTech.split('_')?.[1],
    logos: customisedProduct.state.choices.ll?.split('_')?.[1],
    engraving
  }
}

export default {
  layers: [
    background,
    frame,
    logos,
    lenses,
    engraving
  ],
  resolveOptions,
  resolveOptionsFromSKU
}
